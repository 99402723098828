import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/activities';

export default {
  getActivity(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getModelActivity(filters, model, uid) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${model}/${uid}${queryString}`);
  },
};
