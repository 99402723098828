<style lang="scss"></style>

<template>
  <div>
    <div class="text-center">
      <p>Are you sure you would like to delete this network?</p>
      <strong>{{ network.name }}</strong>
    </div>
    <b-alert class="p-1" :show="groups.length > 0" variant="danger">
      The following groups are still using this network. Please remove the network from these groups and try again
      <div v-for="group in groups" :key="group.uid">
        <br>
        <router-link :to="{ name: 'group-edit', params: { uid: group.uid } }"
                     class="font-weight-bold d-block text-nowrap text-underline"
        >
          {{ group.name }}
        </router-link>
      </div>
    </b-alert>
    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button variant="danger" @click="deleteNetwork">Delete</b-button>
    </section>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars

import networkService from '@/services/NetworkService';
import helperService from '@/services/HelperService';

export default {
  name: 'DeleteNetworkModel',
  components: {},
  props: {
    network: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      groups: [],
    };
  },
  methods: {
    async deleteNetwork() {
      try {
        await networkService.deleteNetwork(this.network.uid)
        helperService.showNotfySuccess(this.$toast, 'Network deleted successfully')
        this.$emit('deleted')
      } catch (err) {
        const res = err.response

        // Check if a meta containing groups is returned
        if (res.data.meta.groups) {
          this.groups = res.data.meta.groups
          return
        }

        let errorText = 'Could not delete network, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        helperService.showNotfyErr(this.$toast, err, errorText)
      }
    },
  },
};
</script>
